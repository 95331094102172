import React, { useRef, useState, useCallback, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useConfig } from '../ConfigProvider'
import cloneDeep from 'lodash/cloneDeep'
import FileItem from './FileItem'
import Button from '../Buttons'
import CloseButton from '../CloseButton'
import Notification from '../Notification'
import toast from '../toast'

const filesToArray = (files) => Object.keys(files).map((key) => files[key])

const Upload = React.forwardRef((props, ref) => {
    const {
        accept,
        beforeUpload,
        disabled,
        draggable,
        fileList,
        multiple,
        onChange,
        onFileRemove,
        showList,
        tip,
        uploadLimit,
        children,
        className,
        field,
        form,
        maxSizeMB,
        ...rest
    } = props

    const fileInputField = useRef(null)
    const [files, setFiles] = useState(fileList)
    const [dragOver, setDragOver] = useState(false)
    const [invalidFiles, setInvalidFiles] = useState([]); // Para destacar arquivos inválidos

    const { themeColor, primaryColorLevel } = useConfig()

    useEffect(() => {
        setFiles(fileList)
    }, [fileList])

    const triggerMessage = (msg) => {
        toast.push(
            <Notification type="danger" duration={5000}>
                {msg || 'Upload Falhou!'}
            </Notification>,
            {
                placement: 'top-center',
            }
        )
    }

    const pushFile = (newFiles, file) => {
        for (let f of newFiles) {
            file.push(f)
        }
        return file
    }
    const addNewFiles = (newFiles) => {
        // Definir o limite máximo de tamanho (em MB) como parâmetro do componente
        const MAX_SIZE_MB = props.maxSizeMB;  // O valor será definido no componente. Se não definido, será undefined.
    
        // Caso não tenha um limite, não verifica o tamanho do arquivo
        const MAX_SIZE_BYTES = MAX_SIZE_MB ? MAX_SIZE_MB * 1024 * 1024 : undefined;
    
        // Definir os tipos de arquivo permitidos
        const allowedTypes = [
            'application/pdf',
            'image/png',
            'image/jpeg',
            'text/plain',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ];
    
        let file = cloneDeep(files);
        const rejectedFiles = [];
        const invalidTypeFiles = [];
        const validFiles = [];
    
        // Verificar arquivos um por um
        Array.from(newFiles).forEach((newFile) => {
            // Verificar tipo do arquivo
            if (!allowedTypes.includes(newFile.type)) {
                invalidTypeFiles.push(`${newFile.name} (tipo inválido)`);
            } 
            // Verificar tamanho do arquivo
            else if (MAX_SIZE_BYTES && newFile.size > MAX_SIZE_BYTES) {
                rejectedFiles.push(`${newFile.name} (excede ${MAX_SIZE_MB} MB)`);
            } else {
                // Se for válido, adiciona à lista de arquivos válidos
                validFiles.push(newFile);
            }
        });
    
        // Emitir alertas para arquivos rejeitados por tipo ou tamanho
        if (invalidTypeFiles.length > 0) {
            triggerMessage(
                `Os seguintes arquivos têm tipo inválido: ${invalidTypeFiles.join(', ')}`
            );
        }
        if (rejectedFiles.length > 0) {
            triggerMessage(
                `Os seguintes arquivos excedem o limite de ${MAX_SIZE_MB ? MAX_SIZE_MB : 'sem limite'}MB: ${rejectedFiles.join(', ')}`
            );
        }
    
        // Verificar o limite de upload, se houver
        if (typeof uploadLimit === 'number' && uploadLimit !== 0) {
            if (Object.keys(file).length + validFiles.length > uploadLimit) {
                // Caso o limite seja 1, remove o primeiro arquivo e adiciona os novos
                if (uploadLimit === 1) {
                    file.shift();
                    file = pushFile(validFiles, file);
                }
    
                return filesToArray({ ...file });
            }
        }
    
        // Adicionar apenas arquivos válidos
        if (validFiles.length > 0) {
            file = pushFile(validFiles, file);
        }
    
        return filesToArray({ ...file });
    };
    
    

   /* const addNewFiles = (newFiles) => {
        let file = cloneDeep(files)
        if (typeof uploadLimit === 'number' && uploadLimit !== 0) {
            if (Object.keys(file).length >= uploadLimit) {
                if (uploadLimit === 1) {
                    file.shift()
                    file = pushFile(newFiles, file)
                }

                return filesToArray({ ...file })
            }
        }
        file = pushFile(newFiles, file)
        return filesToArray({ ...file })
    }*/

    const onNewFileUpload = (e) => {
        const { files: newFiles } = e.target
        let result = true

        if (beforeUpload) {
            result = beforeUpload(newFiles, files)

            if (result === false) {
                triggerMessage()
                return
            }

            if (typeof result === 'string' && result.length > 0) {
                triggerMessage(result)
                return
            }
        }

        if (result) {
            let updatedFiles = addNewFiles(newFiles)
            setFiles(updatedFiles)
            onChange?.(updatedFiles, files)
        }
    }

    const removeFile = (fileIndex) => {
        const deletedFileList = files.filter((_, index) => index !== fileIndex)
        setFiles(deletedFileList)
        onFileRemove?.(deletedFileList)
    }

    const triggerUpload = (e) => {
        if (!disabled) {
            fileInputField.current?.click()
        }
        e.stopPropagation()
    }

    const renderChildren = () => {
        if (!draggable && !children) {
            return (
                <Button disabled={disabled} onClick={(e) => e.preventDefault()}>
                    Upload
                </Button>
            )
        }

        if (draggable && !children) {
            return <span>Insira um ou mais arquivos</span>
        }

        return children
    }

    const handleDragLeave = useCallback(() => {
        if (draggable) {
            setDragOver(false)
        }
    }, [draggable])

    const handleDragOver = useCallback(() => {
        if (draggable && !disabled) {
            setDragOver(true)
        }
    }, [draggable, disabled])

    const handleDrop = useCallback(() => {
        if (draggable) {
            setDragOver(false)
        }
    }, [draggable])

    const draggableProp = {
        onDragLeave: handleDragLeave,
        onDragOver: handleDragOver,
        onDrop: handleDrop,
    }

    const draggableEventFeedbackClass = `border-${themeColor}-${primaryColorLevel}`

    const uploadClass = classNames(
        'upload',
        draggable && `upload-draggable`,
        draggable && !disabled && `hover:${draggableEventFeedbackClass}`,
        draggable && disabled && 'disabled',
        dragOver && draggableEventFeedbackClass,
        className
    )

    const uploadInputClass = classNames(
        'upload-input',
        draggable && `draggable`
    )

    return (
        <>
            <div
                ref={ref}
                className={uploadClass}
                {...(draggable ? draggableProp : { onClick: triggerUpload })}
                {...rest}
            >
                <input
                    className={uploadInputClass}
                    type="file"
                    ref={fileInputField}
                    onChange={onNewFileUpload}
                    disabled={disabled}
                    multiple={multiple}
                    accept={accept}
                    title=""
                    value=""
                    {...field}
                    {...rest}
                ></input>
                {renderChildren()}
            </div>
            {tip}
            {showList && (
                <div className="upload-file-list">
                    {files.map((file, index) => (
                        <FileItem file={file} key={file.name + index}>
                            <CloseButton
                                onClick={() => removeFile(index)}
                                className="upload-file-remove"
                            />
                        </FileItem>
                    ))}
                </div>
            )}
        </>
    )
})

Upload.propTypes = {
    uploadLimit: PropTypes.number,
    draggable: PropTypes.bool,
    disabled: PropTypes.bool,
    showList: PropTypes.bool,
    multiple: PropTypes.bool,
    accept: PropTypes.string,
    tip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

Upload.defaultProps = {
    draggable: false,
    showList: true,
    disabled: false,
    fileList: [],
}

export default Upload
