import React, { memo, useMemo, lazy, Suspense, useEffect } from 'react'
import { Loading } from 'components/shared'
import { useDispatch, useSelector } from 'react-redux'
import {
    LAYOUT_TYPE_CLASSIC,
    LAYOUT_TYPE_MODERN,
    LAYOUT_TYPE_SIMPLE,
    LAYOUT_TYPE_STACKED_SIDE,
    LAYOUT_TYPE_DECKED,
    LAYOUT_TYPE_BLANK,
} from 'constants/theme.constant'
import useAuth from 'utils/hooks/useAuth'
import useDirection from 'utils/hooks/useDirection'
import useLocale from 'utils/hooks/useLocale'
import { HiX } from 'react-icons/hi'
import { hideMultipleTabsDialog, onSignOutSuccess, showMultipleTabsDialog } from 'store/auth/sessionSlice'

const layouts = {
    [LAYOUT_TYPE_CLASSIC]: lazy(() => import('./ClassicLayout')),
    [LAYOUT_TYPE_MODERN]: lazy(() => import('./ModernLayout')),
    [LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import('./StackedSideLayout')),
    [LAYOUT_TYPE_SIMPLE]: lazy(() => import('./SimpleLayout')),
    [LAYOUT_TYPE_DECKED]: lazy(() => import('./DeckedLayout')),
    [LAYOUT_TYPE_BLANK]: lazy(() => import('./BlankLayout')),
}

const Layout = () => {
    const layoutType = useSelector((state) => state.theme.layout.type)
    const { isMultipleTabsDialogVisible, signedIn } = useSelector((state) => state.auth.session); // Obtém o estado para exibir o modal
    const generateSessionKey = () => Math.random().toString(36).substring(2);

    const dispatch = useDispatch();
    const { authenticated } = useAuth()

    useDirection()

    useLocale()

    const AppLayout = useMemo(() => {
        if (authenticated) {
            return layouts[LAYOUT_TYPE_SIMPLE]
        }
        return lazy(() => import('./AuthLayout'))
    }, [layoutType, authenticated])

    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === 'sessionKey') {
                const mainTabKey = localStorage.getItem('sessionKey');
                const currentTabKey = sessionStorage.getItem('sessionKey');
                
                if (mainTabKey !== currentTabKey ) {
                    dispatch(showMultipleTabsDialog());
                } else {
                    dispatch(hideMultipleTabsDialog());
                }
            }
        };
    
        // Adiciona o listener
        window.addEventListener('storage', handleStorageChange);
    
        // Limpa o listener ao desmontar o componente
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [dispatch]);
    const updateSessionKey = (newKey) => {
        sessionStorage.setItem('sessionKey', newKey);
        localStorage.setItem('sessionKey', newKey); // Sincroniza com o localStorage
    };
    useEffect(() => {
        const mainTabKey = localStorage.getItem('sessionKey');
        const currentTabKey = sessionStorage.getItem('sessionKey');
    
        if (!mainTabKey) {
            const newKey = generateSessionKey();
            updateSessionKey(newKey); // Define a nova chave para ambas as storages
            //setIsMainTab(true);
        } else if (mainTabKey !== currentTabKey) {
            dispatch(showMultipleTabsDialog());
        } else {
            dispatch(hideMultipleTabsDialog());
        }
    }, [dispatch]);

    const handleUseHere = () => {
        const newKey = generateSessionKey();
        updateSessionKey(newKey);
        dispatch(hideMultipleTabsDialog());
       // setIsMainTab(true);
    };
    
    
     
      

    const handleCancel = () => {
        console.debug("teste cacelar")
        localStorage.removeItem('sessionKey');
        // sessionStorage.removeItem('sessionKey');
        dispatch(hideMultipleTabsDialog())
     dispatch(onSignOutSuccess())
    };
    return (
        <Suspense
            fallback={
                <div className="flex flex-auto flex-col w-[100vw] h-[100vh]">
                    <Loading loading={true} />
                </div>
            }
        >
               {isMultipleTabsDialogVisible && (
            <div className="w-screen h-screen  fixed z-[1000] flex justify-center items-center bg-[rgba(70,70,70,0.31)]">
               
                    <div className="absolute right-5 top-8">
                <button
                    className="mr-2 ml-2 text-cyan-50 text-[2rem]"
                    shape="circle"
                    type="button"
                    onClick={() => handleCancel()} // Fechar o modal
                >
                    <HiX />
                </button>
                </div>
                <div className="bg-white px-1 py-5 mx-2 md:mx-0 rounded-lg text-center">
                <h3 className="text-gray-800 text-sm font-light p-2 md:px-5">O App está aberto em outra janela. Clique em “Usar nesta janela” para usar o App nesta janela.</h3>
                <div className="mt-2">
                    
                    <button
                    className="bg-gray-500 text-white py-2 px-4 rounded-lg mx-2 my-2"
                    onClick={() => handleCancel()} // Fechar o modal
                    >
                    Cancelar
                </button>
                <button
                    className="bg-violet-600 text-white py-2 px-4 rounded-lg mx-2 my-2"
                    onClick={handleUseHere} // Utilizar a sessão na aba atual
                    >
                   Usar nesta janela
                    </button>
                </div>
                </div>
            </div>
            )}
            {!isMultipleTabsDialogVisible && <AppLayout />}
        </Suspense>
    )
}

export default memo(Layout)
